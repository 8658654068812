.model {
    text-align: center;
}

.background {
    background-color: #EFEFEF;
}

.logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.lifeEducation {
    background: #393448;
}

.knowledgeBased {
    background: #488948;
}

.researchWing {
    background: #1F8F9D;
}

.leaders {
    background: #E87928;
}

.awareness {
    background: #9A1C1F;
}

.mentoring {
    background: #8E4F7C;
}

.infra {
    background: #5D36A7;
}

.evaluation {
    background: #F02A3E;
}
