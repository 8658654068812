@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading {   
    text-align: center;
}

.member-img {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    margin: auto;
    border: 1px solid #EFEFEF;
    object-fit: cover;
}

.card {
    border: 0.25px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #EFEFEF;
    width: 18vw;
    height: 19vw;
    padding-bottom: 24px;
}

.card-mobile {
    border: 0.25px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #EFEFEF;
    width: 100%;
    height: 100%;
}

.member-img-mobile {
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    border: 1px solid #EFEFEF;
    margin-left: 30vw;
    margin-top: 3vw;
    object-fit: cover;
}

.model {
    text-align: center;
}

.background {
    background-color: #EFEFEF;
}

.logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 5px;
}

.lifeEducation {
    background: #393448;
}

.knowledgeBased {
    background: #488948;
}

.researchWing {
    background: #1F8F9D;
}

.leaders {
    background: #E87928;
}

.awareness {
    background: #9A1C1F;
}

.mentoring {
    background: #8E4F7C;
}

.infra {
    background: #5D36A7;
}

.evaluation {
    background: #F02A3E;
}

.icons {
    color: white;
    font-size: 1.8em;
}

/* override css */

.bg-light {
    background-color: #fff !important;
}

.navbar {
    padding-left: 2rem;
    padding-right: 2rem;
}

/* Style the accordion section */
.accordion__section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 0.25px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #EFEFEF;
  margin: 20px 0;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: white;
  cursor: pointer;
  padding: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
  padding-top: 0px;
}

@media only screen and (min-width: 500px) {
    .question {
        padding-left : 25%;
        padding-right : 25%;
    }
}

