.heading {   
    text-align: center;
}

.member-img {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    margin: auto;
    border: 1px solid #EFEFEF;
    object-fit: cover;
}

.card {
    border: 0.25px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #EFEFEF;
    width: 18vw;
    height: 19vw;
    padding-bottom: 24px;
}

.card-mobile {
    border: 0.25px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #EFEFEF;
    width: 100%;
    height: 100%;
}

.member-img-mobile {
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    border: 1px solid #EFEFEF;
    margin-left: 30vw;
    margin-top: 3vw;
    object-fit: cover;
}
