@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  border: 0.25px solid #EFEFEF;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #EFEFEF;
  margin: 20px 0;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: white;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
  padding-top: 0px;
}
