.icons {
    color: white;
    font-size: 1.8em;
}

/* override css */

.bg-light {
    background-color: #fff !important;
}

.navbar {
    padding-left: 2rem;
    padding-right: 2rem;
}
